<template>
  <button class="tolang" @click="switchLang(tolocale);" :class="{active:isActive}" :aria-pressed="isActive">
    {{label}}
  </button>
</template>

<!-- ================================================================================== -->

<script>

export default {
  name:"LangButton",

  props : {
    label : {
      type: String,
      required: true
    },
    tolocale : {
      type: String,
      required: true
    },
    isActive : {
      type: Boolean,
      required: true,
      default: false
    },
  },

  methods: {
    /* CHANGING LOCALE BOTH IN I18N AND STORE */
    switchLang(locale) {
      this.$i18n.locale = locale;
      this.$store.commit('SET_LOCALE', locale);
    },
  }
}

</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
