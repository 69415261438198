// This mixin to receive one-of-a-kind custom function made for ONE form.
// Maybe they can be integrated later to the master, but now, they are appart.
export default {
    data () {
        return {
            customerSituation: null,
            redirectingModaleIsVisible: false,
        }
    },

    computed: {
        userHasIndicatedHeHasParticipated(){
            return !!this.fieldsValues.hasParticipated;
        },
    },

    methods: {

        getFileRef(){
            this.log("getFileRef", 'function');
        },

        goToExternalSite(){
            let dest = "https://www.zininmeer.be/contact";
            if(this.$i18n.locale.slice(0,2) ==='fr'){dest = "https://www.enviedeplus.be/contact";}
            if(this.$i18n.locale.slice(0,2) ==='nl'){dest = "https://www.zininmeer.be/contact";}
            window.location.href=dest;
        },

        closeRedirectingModale(){
            this.redirectingModaleIsVisible = false;
        },

        displayModaleChangeSite(){
            this.log("displayModaleChangeSite()", 'wip');
            this.redirectingModaleIsVisible = true;
        },


        /* TODO When collecting data before sending participation this.gatherTextareaValue() */
        /* Get the textarea value and put it on the right fieldsValues.survey.answersSQX data */
        gatherTextareaValue(){
            this.log("gatherTextareaValue", 'function');
            // switch customerSituation && userHasIndicatedHeHasParticipated => put the value in a specific field
            let safeFreeAnswer = this.fieldsValues.freeAnswer;

            // copy this.fieldsValues.freeAnswer to the right fieldsValues.survey.answersSQXalt
            if(!this.userHasIndicatedHeHasParticipated){
                this.log("User HAS NOT participated", 'wip');

                switch(this.customerSituation){
                case "ccCoupon":
                     // coupon, non participé
                    this.log("CASE: ccCoupon without participation", 'info');
                    this.fieldsValues.survey.answersSQ2alt = safeFreeAnswer;
                    break;
                case "ccCashback":
                    // cashback, non participé
                    this.log("CASE: ccCashback without participation", 'info');
                    this.fieldsValues.survey.answersSQ4alt = safeFreeAnswer;
                    break;
                case "ccContest":
                    // contest, non participé
                    this.log("CASE: ccContest without participation", 'info');
                    this.fieldsValues.survey.answersSQ6alt = safeFreeAnswer;
                    break;
                case "ccPrime":
                    // prime, non participé
                    this.log("CASE: ccPrime without participation", 'info');
                    this.fieldsValues.survey.answersSQ8alt = safeFreeAnswer;
                    break;
                case "ccOther":
                    // other, non participé
                    this.log("CASE: ccOther without participation", 'info');
                    this.fieldsValues.survey.answersSQ10alt = safeFreeAnswer;
                    break;
                /* ------------------------ */
                default:
                    this.log("CASE: default", 'info');
                    break;
                }
            }

            if(this.userHasIndicatedHeHasParticipated){
                this.log("User HAS participated", 'wip');
                switch(this.customerSituation){
                case "ccCoupon":
                    // coupon, participé
                    this.log("CASE: ccCoupon with participation", 'info');
                    this.fieldsValues.survey.answersSQ3alt = safeFreeAnswer;
                    break;
                case "ccCashback":
                    // cashback
                    this.log("CASE: ccCashback with participation", 'info');
                    this.fieldsValues.survey.answersSQ5alt = safeFreeAnswer;
                    break;
                case "ccContest":
                    // contest
                    this.log("CASE: ccContest with participation", 'info');
                    this.fieldsValues.survey.answersSQ7alt = safeFreeAnswer;
                    break;
                case "ccPrime":
                    // prime - free
                    this.log("CASE: ccPrime with participation", 'info');
                    this.fieldsValues.survey.answersSQ9alt = safeFreeAnswer;
                    break;
                case "ccOther":
                    // other
                    this.log("CASE: ccOther with participation", 'info');
                    this.fieldsValues.survey.answersSQ11alt = safeFreeAnswer;
                    break;
                /* ------------------------ */
                default:
                    this.log("CASE: default", 'info');
                    break;
                }
            }


            // set a specific data fieldsValues

        },


        /* On the "journey" page the user must choose if he has participated or not */
        wayOfParticipationDone(bool){
            this.log(bool?"User has participated":"User has not participated", 'info');

            let oldValue = this.userHasIndicatedHeHasParticipated;
            let newValue = bool;
            this.userHasIndicatedHeHasParticipated = bool;
            
            if (oldValue !== newValue){
                this.cleanPrefilledValues(bool);
            }
            
            this.goto('form1');
        },


        cleanPrefilledValues(bool){
            // bool true : user has participated
            if(bool){
                this.cleanNonParticipatedValues();
            }
                // bool false : user has not participated
            if(!bool){
                this.cleanParticipatedValues();
            }
        },

        cleanParticipatedValues(){
            this.log("Cleaning 'Participated' value", 'wip');
            this.fieldsValues.iban = null;
            this.fieldsValues.freeAnswer = null;
            /* TODO Loopable */
            this.fieldsValues.survey.answersSQ1 = null;
            this.fieldsValues.survey.answersSQ1alt = null;
            this.fieldsValues.survey.answersSQ3 = null;
            this.fieldsValues.survey.answersSQ3alt = null;
            this.fieldsValues.survey.answersSQ5 = null;
            this.fieldsValues.survey.answersSQ5alt = null;
            this.fieldsValues.survey.answersSQ7 = null;
            this.fieldsValues.survey.answersSQ7alt = null;
            this.fieldsValues.survey.answersSQ9 = null;
            this.fieldsValues.survey.answersSQ9alt = null;
            this.fieldsValues.survey.answersSQ11 = null;
            this.fieldsValues.survey.answersSQ11alt = null;
        },

        cleanNonParticipatedValues(){
            this.log("Cleaning 'Non Participated' value", 'wip');
            this.fieldsValues.freeAnswer = null;
            /* TODO Loopable */
            this.fieldsValues.survey.answersSQ2 = null;
            this.fieldsValues.survey.answersSQ2alt = null;
            this.fieldsValues.survey.answersSQ4 = null;
            this.fieldsValues.survey.answersSQ4alt = null;
            this.fieldsValues.survey.answersSQ6 = null;
            this.fieldsValues.survey.answersSQ6alt = null;
            this.fieldsValues.survey.answersSQ8 = null;
            this.fieldsValues.survey.answersSQ8alt = null;
            this.fieldsValues.survey.answersSQ10 = null;
            this.fieldsValues.survey.answersSQ10alt = null;
        },








        checkInUrlIfThereIsSpecificPageAsked(){
            // check the url, go to the appropriate page.
            let urlParams = new URLSearchParams(window.location.search);
            let urlHasPage = urlParams.has('page');

            let pageValue = null;
            let pageValueIsAllowed = false;

            if(urlHasPage){
                this.log("page is detected", "green");
                pageValue = urlParams.get('page').toString();
                pageValueIsAllowed = ["coupon", "cashback", "contest", "prime", "other"].includes(pageValue);
            }else{
                this.log("page was not detected", 'low');
                this.firstPageOfThisForm = 'wrongcode2';
            }

            if(pageValueIsAllowed){
                this.log("page is allowed", "green");
            }else{
                this.log(`Page is not present or value not recognized (value is ${pageValue})`, 'alert');
            }

            if(urlHasPage && pageValueIsAllowed ){
                this.log("both condition reunited");
                if(pageValue === "coupon"){ this.customerSituation = 'ccCoupon'; }
                if(pageValue === "cashback"){ this.customerSituation = 'ccCashback'; }
                if(pageValue === "contest"){ this.customerSituation = 'ccContest'; }
                if(pageValue === "prime"){ this.customerSituation = 'ccPrime'; }
                if(pageValue === "other"){ this.customerSituation = 'ccOther'; }

                this.log(`customerSituation changed to ${this.customerSituation}`, 'low');
            }else{
                this.log("page conditions not met, customerSituation undefined", 'alert');
                this.firstPageOfThisForm = 'wrongcode2';
            }
            this.log(`customerSituation is ${this.customerSituation}`, 'wip');
        },

        changeTitleOfPage(){
            let titlePage = document.querySelector('title');        
            let self = this;
            let replaceTitleBy = "Contact Center";
            try{
                this.log(`Customer Situation: ${this.customerSituation}`, 'wip');
                switch(this.customerSituation){
                case "ccCoupon":
                    replaceTitleBy = self.$t('tabTitles.coupons');
                    break;
                case "ccCashback":
                    this.log("ccCashback");
                    replaceTitleBy = self.$t('tabTitles.cashbacks');
                    this.log(`replaceTitleBy = "${replaceTitleBy}"`);
                    break;
                case "ccContest":
                    replaceTitleBy = self.$t('tabTitles.contests');
                    break;
                case "ccPrime":
                    replaceTitleBy = self.$t('tabTitles.primes');
                    break;
                case "ccOther":
                    replaceTitleBy = self.$t('tabTitles.others');
                    break;
                default: 
                    replaceTitleBy = "Contact Center"
                }
                titlePage.textContent = replaceTitleBy;
            }catch(e){
                this.log(e);
            }
        },

        // was het last method

    },

    mounted(){

    },


    watch: {
        'fieldsValues.hasParticipated': function(){
                    // If user change his answer : has or has not participated, the field adapt, but are not emptied
            this.log(`Form change of value: ${this.fieldsValues.hasParticipated}`);
            if( this.fieldsValues.hasParticipated === 0 ){
                this.cleanParticipatedValues();
            }
            if( this.fieldsValues.hasParticipated === 1 ){
                this.cleanNonParticipatedValues();
            }
        }
    }

}

