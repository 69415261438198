<template>
  <div v-if="langswitchsThatMustBePresent.length >= 1 && modelLangButton === 'country'">
    <div class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}" v-if="langswitchsThatMustBePresent.length > 1 ">

      <lang-button tabindex="0" aria-label="Belgique" label="Belgique" tolocale="fr_BE" v-if="langswitchsThatMustBePresent.includes('FR_BE')" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
      <lang-button tabindex="0" aria-label="België" label="België" tolocale="nl_BE" v-if="langswitchsThatMustBePresent.includes('NL_BE')" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
      <lang-button tabindex="0" aria-label="Belgium" label="Belgium" tolocale="en_BE" v-if="langswitchsThatMustBePresent.includes('EN_BE')" :isActive="$i18n.locale === 'en_BE'"></lang-button>

      <lang-button tabindex="0" aria-label="Luxembourg" label="Luxembourg" tolocale="fr_LU" v-if="langswitchsThatMustBePresent.includes('FR_LU')" :isActive="$i18n.locale === 'fr_LU'"></lang-button>
      <lang-button tabindex="0" aria-label="France" label="France" tolocale="fr_FR" v-if="langswitchsThatMustBePresent.includes('FR_FR')" :isActive="$i18n.locale === 'fr_FR'"></lang-button>

      <lang-button tabindex="0" aria-label="Nederland" label="Nederland" tolocale="nl_NL" v-if="langswitchsThatMustBePresent.includes('NL_NL')" :isActive="$i18n.locale === 'nl_NL'"></lang-button>

      <lang-button tabindex="0" aria-label="Deutschland" label="Deutschland" tolocale="de_DE" v-if="langswitchsThatMustBePresent.includes('DE_DE')" :isActive="$i18n.locale === 'de_DE'"></lang-button>
      <lang-button tabindex="0" aria-label="Schweiz" label="Switzerland" tolocale="de_CH" v-if="langswitchsThatMustBePresent.includes('DE_CH')" :isActive="$i18n.locale === 'de_CH'"></lang-button>
      <lang-button tabindex="0" aria-label="Suisse" label="Suisse" tolocale="fr_CH" v-if="langswitchsThatMustBePresent.includes('FR_CH')" :isActive="$i18n.locale === 'fr_CH'"></lang-button>
      <lang-button tabindex="0" aria-label="United Kingdom" label="United Kingdom" tolocale="en_UK" v-if="langswitchsThatMustBePresent.includes('EN_UK')" :isActive="$i18n.locale === 'en_UK'"></lang-button>

    </div>
  </div>

  <div v-else-if="langswitchsThatMustBePresent.length >= 1 && modelLangButton === 'language'">
    <div class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}" v-if="langswitchsThatMustBePresent.length > 1 ">
      <lang-button tabindex="0" @click="checkLanguage()" :name="currentNameFR" :aria-label="currentNameFR" label="FR" tolocale="fr_BE" v-if="langswitchsThatMustBePresent.some(i=> ['FR_BE', 'FR_FR', 'FR_LU', 'FR_CH'].includes(i))" :isActive="$i18n.locale === 'fr_BE' || $i18n.locale === 'fr_FR' || $i18n.locale === 'fr_LU' || $i18n.locale === 'fr_CH'"></lang-button>
      <lang-button tabindex="0" @click="checkLanguage()" :name="currentNameNL" :aria-label="currentNameNL" label="NL" tolocale="nl_BE" v-if="langswitchsThatMustBePresent.some(i=> ['NL_BE', 'NL_NL'].includes(i))" :isActive="$i18n.locale === 'nl_BE' || $i18n.locale === 'nl_NL'"></lang-button>
      <lang-button tabindex="0" aria-label="deutsch" label="DE" tolocale="de_DE" v-if="langswitchsThatMustBePresent.some(i=> ['DE_BE', 'DE_DE', 'DE_CH'].includes(i))" :isActive="$i18n.locale === 'de_BE' || $i18n.locale === 'de_DE' || $i18n.locale === 'de_CH'"></lang-button>
      <lang-button tabindex="0" aria-label="english" label="EN" tolocale="en_BE" v-if="langswitchsThatMustBePresent.some(i=> ['EN_BE', 'EN_UK'].includes(i))" :isActive="$i18n.locale === 'en_BE' || $i18n.locale === 'en_UK'"></lang-button>
    </div>
  </div>

  <!-- DEFAULT lang in case of maintenance -->
  <div v-else class="langswitcher" :class="{inheader: inHeader, outheader: !inHeader}">
    <lang-button tabindex="0" :name="currentNameFR" aria-label="français" label="FR" tolocale="fr_BE" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
    <lang-button tabindex="0" :name="currentNameNL" aria-label="nederlands" label="NL" tolocale="nl_BE" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
  </div>
</template>

<!-- ================================================================================== -->

<script>

import LangButton from '@/components/LangButton';
import Mixins from "../mixins/NonAutomatedData";

export default {
  name:"langswitcher",

  props: {
    inHeader: {
      type: Boolean,
      required: false,
      default: true,
    }
  },

  components: {
    LangButton
  },
  mixins: [Mixins],

  data(){
    return {
      langswitchsThatMustBePresent: this.$store.getters.getLangList,
      currentNameFR: "Selecteer taal: frans",
      currentNameNL: "Selecteer taal: nederlands",
    }
  },

  methods: {
    checkLanguage(){
      if(this.$i18n.locale === "nl_BE"){
        this.currentNameFR = "Selecteer taal: frans";
        this.currentNameNL = "Selecteer taal: nederlands";
      }else{
        this.currentNameFR = "Selectionner la langue: français";
        this.currentNameNL = "Selectionner la langue: néerlandais";
      }
      return this.currentNameFR && this.currentNameNL;
    }

  },

  mounted(){
    this.checkLanguage();
  },

  watch: {
    // To be sure it load at startup
    '$store.state.langList': function(/* newLocale, oldLocale */){
      this.langswitchsThatMustBePresent = this.$store.getters.getLangList;
    },
  },
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
