<template>
	<div class="aTextArea field">
		<label  :for="fieldname">{{label}} <span v-if="isRequired" class="req" tabindex="-1">*</span></label>

		<div class="helper">{{helper}}</div>

		<div class="inputwrapper">

			<textarea 
			:name="fieldname" 
			:id="fieldname"
			v-model="modelValueComputed"
			:placeholder="placeholder"
			:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
			tabindex="0"
			:maxlength="limitCharTo"
			></textarea>

			<div class="limitCharMention" v-show="limitCharTo > 0">{{$t('inputsTexts.textArea.charsLeftBegin')}} {{charactersLeft}} <span v-show="charactersLeft > 1">{{$t('inputsTexts.textArea.charsLeftEnd')}}</span><span v-show="charactersLeft <= 1">{{$t('inputsTexts.textArea.charLeftEnd')}}</span>
			</div>

		</div>
		<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
		
	</div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
	import { debounce } from "lodash";
	import FormFields from "../mixins/FormFields";
	import config from "../config";
	import CustomLog from "../mixins/CustomLog";

	export default {
		name: "TextArea",

		emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

		mixins: [
			CustomLog,
			FormFields,
			],

		props: {
			inputType:{
				type: String,
				validator : function(value){
					return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
				}
			},
			label : {
				type: String,
				required : true
			},
			isDisabled : {
				type: Boolean,
				required: false,
			default: false
			},
			acceptedValues: {
				type: Array, 
				required: false
			},
			valueHasChanged: {
				type: String,
				required: false
			},
			limitCharTo: {
				type: Number,
				required: true
			}
		},

		data(){
			return {
				errorMessage : "",
				config: config,
			}
		},

		computed: {

			charactersLeft(){
				let currentChar = 0;
				if(this.modelValueComputed){
					currentChar = this.modelValueComputed.length
				}
				let sumChars = this.limitCharTo - currentChar			
				return sumChars < 0 ? 0 : sumChars;
			},

			modelValueComputed: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.validateMyselfDebounced();
					this.$emit('update:modelValue', newVal);
				}
			}
		},

		methods : {


			validateMyself() {
				this.removeSuccessOrError();
				this.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`, 'low');
        /* CUSTOM VALIDATION */

				/* To be valid, the filed must contain at least 1 caracters */
				if(this.modelValueComputed.length >= 1){
					this.log("Value accepted", 'low');
					this.giveSuccess();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
				}else{
					this.log("Value rejected", 'low');
					this.giveError();
					this.errorMessage = this.$t('inputsTexts.textArea.error_wrongValue');
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				}
			},

		},

		created() {
			this.validateMyselfDebounced = debounce(this.validateMyself, 500);
    // run validation immediately when the value is not null or empty
			if(this.modelValueComputed) {
				this.validateMyselfDebounced();
			}
		},

		watch: {
			modelValue(){
				this.validateMyselfDebounced();
			},
		},

	}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
