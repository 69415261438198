<template>
  <div class="aFreeInput field">
    <label  :for="fieldname">{{label}} <span v-if="isRequired" class="req" tabindex="-1">*</span></label>
    <div class="inputwrapper">
      <input
      :id="fieldname"
      :type="inputType"
      v-model="modelValueComputed"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
      tabindex="0"
      >
      <icons-svg icon="cadenas" class="smallfieldicon" v-if="isDisabled"></icons-svg>
    </div>
    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper">{{helper}}</div>
  </div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
  import { debounce } from "lodash";
  import FormFields from "../mixins/FormFields";
  import dayjs from "dayjs";
  import config from "../config";
  import CustomLog from "../mixins/CustomLog";
  import IconsSvg from '@/components/IconsSvg';
  var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
  dayjs.extend(isSameOrBefore)
  export default {
    name: "customInput",

    emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

    mixins: [
      CustomLog,
      FormFields,
      ],

    components: {
      IconsSvg,
    },

    props: {
      inputType:{
        type: String,
        validator : function(value){
          return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
        }
      },
      label : {
        type: String,
        required : true
      },
      isDisabled : {
        type: Boolean,
        required: false,
      default: false
      },
      acceptedValues: {
        type: RegExp, 
        required: false
      },
      valueHasChanged: {
        type: String,
        required: false
      },
    },

    data(){
      return {
        errorMessage : "",
        config: config,
      }
    },

    computed: {
      modelValueComputed: {
        get() {
          return this.modelValue;
        },
        set(newVal) {
          newVal = newVal.replace('-', '_');
          this.validateMyselfDebounced();
          this.$emit('update:modelValue', newVal);
        }
      }
    },

    methods : {


      validateMyself() {
        this.setLoading();
        this.removeSuccessOrError();
        this.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`, 'low');
        /* CUSTOM VALIDATION */
        /* Acceptables values are given by a prop: acceptedValues */
        /* If none are given, a non-empty text is enough to validate the field */
        if (this.acceptedValues){
          this.thereIsAListOfAcceptedValues();
        }else{
          this.thereIsNoListOfAcceptedValues();
        }


        this.setNotLoading();
      },

      thereIsAListOfAcceptedValues(){
        this.log("thereIsAListOfAcceptedValues", 'function');

        if(this.acceptedValues.test(this.modelValueComputed) && this.modelValueComputed.length <= 18){          
          this.log("Value accepted", 'low');
          this.giveSuccess();
          this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
        }else{
          this.log("Value rejected", 'low');
          this.giveError();
          this.errorMessage = this.$t('inputsTexts.refInput.error_wrongValue');
          this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
        }
      },

      thereIsNoListOfAcceptedValues(){
        this.log("thereIsNoListOfAcceptedValues", 'function');
        this.log("There is a problem, should not be empty list", 'alert');
      },


    },

    created() {
      this.validateMyselfDebounced = debounce(this.validateMyself, 500);
    // run validation immediately when the value is not null or empty
      if(this.modelValueComputed) {
        this.validateMyselfDebounced();
      }
    },

    watch: {
      modelValue(){
        this.validateMyselfDebounced();
      },
    },

  }

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
